import {Fragment, useState} from 'react';
import {Field, Form} from 'react-final-form';
import {
  Fieldset,
  FormSection,
  Legend,
  Section,
} from 'components/Form/section';
import {Input} from 'components/Form/Inputs/TextField';
import axios from 'axios';
import { useRouter } from 'next/router';
import { HashLoader } from 'react-spinners';
import SimpleTable from 'components/common/Table';

const Search = () => {
    const router = useRouter();
  async function onSubmit (data) {
    search (data.search.key);
  }
  const [leadList, setLeadList] = useState (null);
  const [error, setError] = useState (null);
  const [loading, setLoading] = useState (false);
  const [emptyList, setEmptyList] = useState (true);
  const [searchSuccess, setSearchSuccess] = useState(null);
  const columns = [
    
    {
      dataIndex: "name",
      key: "name",
      title: "Lead Name"
    },
    {
      dataIndex: "email",
      key: "email",
      title: "Lead Email"
    },
    {
      dataIndex: "mobile",
      key: "mobile",
      title: "Lead Phone"
    },
    {
      dataIndex: "owner",
      key: "owner",
      title: "Lead Owner"
    },
    {
      dataIndex: "stage",
      key: "stage",
      title: "Stage"
    }
  ];
  const onRowClick = (record, index, event) => {
    router.push(`/bankapplication/${record.slug}`)
  }
  async function search (query, filter) {
    setLoading(true);
    setError(null);
    if(filter){
      setSearchSuccess(filter)
    }
    if(query){
      setSearchSuccess(query)
    }
    let url = '/api/route?path=bank_account/search'
    let params = { params:  {
        query: query,
      }};
      if(filter){
        url = '/api/route?path=bank_account/filter/'+filter
        params = null
      }
      axios.get (
        url,
        params
      )
      .then (response => {
        setLoading (false);
        if (response) {
          if(response.data.success == false){
            return setError(response.data.message)
           }
          setLeadList (response.data.data);
          setEmptyList (false);
          if (!response.data.data || response.data.data.length === 0) {
            setEmptyList (true);
            setSearchSuccess (null);
            setError ('No leads found, search with different input');
          }
        }
      })
      .catch (err => {
        setLoading (false);
        setError ('Failed to fetch data from server, contact tech team.');
        setEmptyList (true);
        console.log (err);
      });     
  }
  return (
    <Fragment>
      <FormSection
        title=""
        subheading=""
        style={{paddingLeft: '15px', width: '100%', background: 'yellow'}}
      >
        <Form onSubmit={onSubmit}>
          {({handleSubmit, values, errors, touched, submitting, ...rest}) => (
            <div className="w-full">
              <form onSubmit={handleSubmit}>
                <fieldset style={{width: '1000px'}}>
                  <Section>
                    <Fieldset>
                      <Legend>
                        Search By Phone or FirstName
                      </Legend>
                      <div className="mb-4 md:mb-6" style={{width: '60%'}}>
                        <Field
                          name="search.key"
                          component={Input}
                          label="Phone/First-Name"
                          validate={v => {
                            if (!v) return 'Mandatory';
                          }}
                        />
                      </div>
                      <div className="save-button">
                        <button onClick={handleSubmit}>
                          Search
                        </button>
                      </div>
                    </Fieldset>
                  </Section>
                </fieldset>
              </form>
            </div>
          )}
        </Form>
      </FormSection>
      <Fragment>
              <Fragment>
                {loading && (
                <div className="loader">
                  <HashLoader color="#443eff" />
                </div>
                )}
                {error ? (
                  <h2 className="text-4xl text-center mb-6" style={{ color: "red" }}>{error}</h2>
                  ) : (
                    !emptyList && (
                      <div>
                        <h2 className="text-4xl text-center mb-6">
                          Search Results For "{searchSuccess}"
                        </h2>
                        <Fragment>
                          <div className="w-full mt-6 px-8">
                            <SimpleTable
                              columns={columns}
                              data={leadList}
                              onRow={(record, index) => ({
                                onClick: onRowClick.bind(null, record, index),
                              })}
                              rowClassName="hover:bg-gray-100"
                            />
                          </div>
                        </Fragment>
                      </div>
                    )
                  )}
              </Fragment>
        </Fragment>
    </Fragment>
  );
};

export default Search;
