export const refiPostLoginStages = {
  REFINANCE_OFFER_CONFIRMATION: "REFINANCE_OFFER_CONFIRMATION",
  REFINANCE_SENT_TO_CREDIT: "REFINANCE_SENT_TO_CREDIT",
  REFINANCE_FINAL_NEGOTIATED_OFFER: "REFINANCE_FINAL_NEGOTIATED_OFFER",
  REFINANCE_PF_PENDING: "REFINANCE_PF_PENDING",
  REFINANCE_SANCTION_GENERATED: "REFINANCE_SANCTION_GENERATED",
  REFINANCE_POST_SANCTION_NEGOTIATION: "REFINANCE_POST_SANCTION_NEGOTIATION",
  REFINANCE_BANK_DETAILS_COLLECTION: "REFINANCE_BANK_DETAILS_COLLECTION",
  REFINANCE_OK_TO_DISBURSE: "REFINANCE_OK_TO_DISBURSE",
  REFINANCE_CONTRACT_REVIEW: "REFINANCE_CONTRACT_REVIEW",
  REFINANCE_CONTRACT_PENDING: "REFINANCE_CONTRACT_PENDING",
  REFINANCE_REJECTED: "REFINANCE_REJECTED",
  REFINANCE_CONTRACT_APPROVED: "REFINANCE_CONTRACT_APPROVED",
  REFINANCE_HELD_BY_CREDIT: "REFINANCE_HELD_BY_CREDIT",
};

export const stageOrderMapper = {
  REFINANCE_OFFER_CONFIRMATION: 9,
  REFINANCE_SENT_TO_CREDIT: 10,
  REFINANCE_CONTRACT_PENDING: 16,
}