import axios from "axios";
import { useRouter } from "next/router";
import React, { Fragment, useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { HashLoader } from "react-spinners";
import { Input } from "components/Form/Inputs/TextField";
import {
  Fieldset,
  FormSection,
  Legend,
  Section,
} from "components/Form/section";
import ReactPaginate from "react-paginate";
import { refiPostLoginStages } from "DataMapper/refiStages";
import { Submit } from "components/Form/Inputs/Submit";
import SimpleTable from "components/common/Table";

function Search() {
  const [leadList, setLeadList] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [emptyList, setEmptyList] = useState(true);
  const [searchSuccess, setSearchSuccess] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [filterStage, setFilterStage] = useState(null);

  const router = useRouter();

  useEffect(() => {
    setError(null);
    setLeadList([]);
  }, []);

  useEffect(() => {
    if (filterStage) {
      search(null, filterStage, 0);
    }
  }, [filterStage]);

  function onSubmit(data) {
    search(data.search.key);
  }

  async function search(searchKey, filter, pageNo) {
    setLeadList([]);
    setLoading(true);
    setError(null);
    setPageCount(0);
    let endpoint;
    let params;
    if (searchKey) {
      endpoint = "/api/route?path=refi/portal/lead-search";
      params = {
        params: {
          searchKey: searchKey,
        },
      };
    }
    if (filter) {
      endpoint = `/api/route?path=refi/portal/lead/${filter}?page=${pageNo}`;
      params = null;
    }
    axios.get(endpoint, params)
      .then((response) => {
        setLoading(false);
        if (response) {
          setSearchSuccess(searchKey ? searchKey : filter);
          setLeadList(
            searchKey ? response.data.data : response.data.data.refiLeadDto
          );
          setEmptyList(false);
          if (filter) {
            setPageCount(response.data.data.pages);
          }
          const length = searchKey
            ? response.data.data
            : response.data.data.refiLeadDto.length;
          if (length == 0) {
            setEmptyList(true);
            setSearchSuccess(null);
            setError("No leads found, search with different input");
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        setSearchSuccess(null);
        setError("Failed to fetch data from server, contact tech team.");
        setEmptyList(true);
        console.log(err);
      });
  }

  const handlePageClick = (event) => {
    search(null, filterStage, event.selected);
  };

  const onRowClick = (record, index, event) => {
    router.push(`/refiApplication/${record.slug}`);

  };

  const columns = [
    {
      dataIndex: "id",
      key: "id",
      title: "AuthId",
    },
    {
      dataIndex: "name",
      key: "name",
      title: "Lead Name",
    },
    {
      dataIndex: "email",
      key: "email",
      title: "Lead Email",
    },
    {
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      title: "Lead Phone",
    },
  ];

  return (
    <Fragment>
      {loading ? (
        <div className="loader">
          <HashLoader color="#443eff" />
        </div>
      ) : (
        <div>
          <FormSection
            title=""
            subheading=""
            style={{
              paddingLeft: "15px",
              width: "100%",
              background: "yellow",
            }}
          >
            <Form onSubmit={onSubmit}>
              {({ handleSubmit, submitting }) => (
                <div className="w-full">
                  <form onSubmit={handleSubmit}>
                    <fieldset style={{ width: "1000px" }}>
                      <Section>
                        <Fieldset>
                          <Legend>Search By Phone or Slug or First-Name</Legend>
                          <div
                            className="mb-4 md:mb-6"
                            style={{ width: "60%" }}
                          >
                            <Field
                              name="search.key"
                              component={Input}
                              label="Phone/Slug/First-Name"
                              validate={(v) => {
                                if (!v) return "Mandatory";
                              }}
                            />
                          </div>
                          <Submit
                            onClick={handleSubmit}
                            submitting={submitting}
                          />
                          <div
                            className="max-w-page-width m-auto"
                            style={{
                              marginTop: "20px",
                              marginBottom: "10px",
                            }}
                          >
                            <Legend>Search By Offer Stages</Legend>
                            {Object.keys(refiPostLoginStages).map(
                              (Stage, idx) => {
                                return (
                                  <div
                                    className="save-like-button-small-text"
                                    style={{
                                      display: "inline-block",
                                      marginRight: "10px",
                                      marginBottom: "10px",
                                    }}
                                    key={`refinance-stage-${idx}`}
                                  >
                                    <button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setFilterStage(Stage);
                                      }}
                                    >
                                      {Stage}
                                    </button>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </Fieldset>
                      </Section>
                    </fieldset>
                  </form>
                </div>
              )}
            </Form>
          </FormSection>
          <Fragment>
            <Fragment>
              {error ? (
                <h2
                  className="text-2xl text-center mb-6"
                  style={{ color: "red" }}
                >
                  {error}
                </h2>
              ) : (
                !emptyList && (
                  <div>
                    <h2 className="text-2xl text-center mb-6">
                      Search Results For "{searchSuccess}"
                    </h2>
                    <Fragment>
                      <div className="w-full mt-6 px-8">
                        <SimpleTable
                          columns={columns}
                          data={leadList}
                          onRow={(record, index) => ({
                            onClick: onRowClick.bind(null, record, index),
                          })}
                          rowClassName="hover:bg-gray-100"
                        />
                      </div>
                    </Fragment>
                  </div>
                )
              )}
            </Fragment>
          </Fragment>
        </div>
      )}
      <ReactPaginate
        previousLabel="<"
        nextLabel=">"
        pageCount={pageCount}
        onPageChange={handlePageClick}
        renderOnZeroPageCount={null}
        containerClassName="w-full flex justify-center my-4 text-gray-500 items-center"
        disabledClassName=""
        pageLinkClassName="py-1 px-2 m-1 cursor-pointer font-bold text-xl focus:text-white hover:bg-primary hover:text-white rounded"
        nextLinkClassName="py-1 px-2 m-1 pointer-events-none cursor-pointer font-bold text-xl hover:text-yellow-600"
        previousLinkClassName="py-1 px-2 m-1 pointer-events-none cursor-pointer font-bold text-xl hover:text-yellow-600"
        activeLinkClassName="bg-primary rounded text-white"
      />
    </Fragment>
  );
}

export default Search;
