const Filter = ({ filter,handleFilter }) => {
  return (
    <select
      style={{ marginLeft: "85%" }}
      className="border p-3 border-black rounded"
      onChange={(e) => {
        handleFilter(e.target.value, "UE_REJECTED");
      }}
      value={filter}
    >
      <option value="All">All Reasons</option>
      <option value="user_age">User Age</option>
      <option value="user_bureau_score">User Experian</option>
      <option value="backlog">Backlogs</option>
      <option value="app_score">App Score</option>
      <option value="cosigner_age">Cosigner Age</option>
      <option value="cosigner_bureau">Cosigner Experian</option>
    </select>
  );
};

export default Filter;
