export const ueColumns = [
  {
    field: "leadAuthId",
    headerName: "Lead AuthId",
    flex: 1,
  },
  {
    field: "leadName",
    headerName: "Lead Name",
    flex: 2,
  },
  {
    field: "leadEmail",
    headerName: "Lead Email",
    flex: 2,
  },
  {
    field: "leadPhone",
    headerName: "Lead Phone",
    flex: 1,
  },
  {
    field: "leadOwner",
    headerName: "Lead Owner",
    flex: 2,
  },
  {
    field: "rejectedReason",
    headerName: "Rejection Reason",
    flex: 2,
  },
];