import { getUserDetails } from "../pages/api/commonApis";

export const trackLogin = async () => {
  try {
    const eventName = "IP Login Successful";
    const res = await getUserDetails();
    const { leapUserId, email } = res?.data?.data || {};
    const userPayload = {
      email: email || "NA",
    };
    if (window.analytics) {
      const trackEventPromise = new Promise((resolve, reject) => {
        window.analytics.track(eventName, {}, {}, () => {
          resolve(true);
        });
      });
      const identifyEventPromise = new Promise((resolve, reject) => {
        window.analytics.identify(leapUserId, userPayload, {}, () => {
          resolve(true);
        });
      });
      return Promise.all([trackEventPromise, identifyEventPromise]);
    }
  } catch (error) {
    console.error(error);
    return true;
  }
};
