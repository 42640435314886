import { useRouter } from "next/router";
import React, { Fragment, useEffect, useState } from "react";
import { HashLoader } from "react-spinners";
import axios from "axios";
import Layout from "../components/Layout/Layout";
import { Field, Form } from "react-final-form";
import {
  Fieldset,
  FormSection,
  Legend,
  Section,
} from "../components/Form/section";
import { Input } from "../components/Form/Inputs/TextField";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import BankAccount from "../components/BankAccountLeadDetails/search";
import RefiAccount from "../components/RefiAccountLeadDetails/Search";
import Filter from "../components/UEReason/Filter";
import { ueColumns } from "../components/UEReason/Columns";
import SearchDisbursementLead from "../components/DisbursementApplicationLeadDetails/Search";
import dynamic from "next/dynamic";
import { trackLogin } from "scripts/segment";
import { DataGrid } from '@mui/x-data-grid';
import useTabStyles from "lib/utils/useTabStyles";
import { dataGridCommonProps } from "lib/utils/constants";
import { isValidLeadSearchInput } from "lib/utils/validations";

const ManualCSVUploads = dynamic(() =>
  import("../components/ManualCSVUploads")
);

const Home = () => {
  const router = useRouter();
  const [leadList, setLeadList] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [emptyList, setEmptyList] = useState(true);
  const [searchSuccess, setSearchSuccess] = useState(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [rejectFilter, setRejectFilter] = useState("All");
  const { shouldTriggerEvent } = router.query || {};
  useEffect(() => {
    if (!!shouldTriggerEvent) {
      trackLogin();
      router.replace("/");
    }
  }, [shouldTriggerEvent]);

  const handleChange = (event, newValue) => {
    setActiveTabIndex(newValue);
  };
  const classes = useTabStyles();
  useEffect(() => {
    setError(null);
    setLeadList([]);
  }, []);

  async function onSubmit(data) {
    search(data.search.key);
  }

  async function search(searchKey) {
    setRejectFilter("All");
    setLeadList([]);
    setLoading(true);
    setError(null);
    axios
      .get("/api/route?path=portal/lead-search", {
        params: {
          searchKey: searchKey,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response) {
          setSearchSuccess(searchKey);
          setLeadList(response.data);
          setEmptyList(false);
          if (response.data.length == 0) {
            setEmptyList(true);
            setSearchSuccess(null);
            setError("No leads found, search with different input");
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        setSearchSuccess(null);
        setError("Failed to fetch data from server, contact tech team.");
        setEmptyList(true);
        console.log(err);
      });
  }

  const columns = [
    {
      field: "leadAuthId",
      headerName: "Lead AuthId",
      sortable: false,
      flex: 1
    },
    {
      field: "leadName",
      headerName: "Lead Name",
      sortable: false,
      flex: 2
    },
    {
      field: "leadEmail",
      headerName: "Lead Email",
      sortable: false,
      flex: 2
    },
    {
      field: "leadPhone",
      headerName: "Lead Phone",
      sortable: false,
      flex: 1
    },
    {
      field: "leadOwner",
      headerName: "Lead Owner",
      sortable: false,
      flex: 2
    },
  ];

  async function handleFilter(filterKey, searchKey) {
    setLeadList([]);
    setLoading(true);
    setError(null);
    if (filterKey === "All") {
      search("UE_REJECTED");
      return;
    }
    axios
      .get("/api/route?path=portal/lead-search", {
        params: {
          searchKey: searchKey,
          filter: filterKey,
        },
      })
      .then((response) => {
        setLoading(false);
        setRejectFilter(filterKey);
        if (response) {
          setSearchSuccess(searchKey);
          setLeadList(response.data);
          setEmptyList(false);
          if (response.data.length == 0) {
            setEmptyList(true);
            setSearchSuccess(null);
            setError("No leads found, search with different input");
          }
        }
      })
      .catch((err) => {
        setRejectFilter(filterKey);
        setLoading(false);
        setSearchSuccess(null);
        setError("Failed to fetch data from server, contact tech team.");
        setEmptyList(true);
        console.log(err);
      });
  }

  return (
    <Layout>
      {() => (
        <>
          <h1 className="text-2xl bold text-center mb-6">Leap Finance Portal</h1>
          <Paper square className={classes.root}>
            <Tabs
              value={activeTabIndex}
              onChange={handleChange}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab className={classes.tab} label="Loan application" />
              <Tab className={classes.tab} label="Bank Application" />
              <Tab className={classes.tab} label="Refi Application" />
              <Tab className={classes.tab} label="Disbursement Application" />
              <Tab className={classes.tab} label="Manual CSV Uploads" />
            </Tabs>
          </Paper>
          {activeTabIndex === 0 && (
            <Fragment>
              {loading ? (
                <div className="loader">
                  <HashLoader color="#443eff" />
                </div>
              ) : (
                <div>
                  <FormSection
                    title=""
                    subheading=""
                    style={{
                      paddingLeft: "15px",
                      width: "100%",
                      background: "yellow",
                    }}
                  >
                    <Form onSubmit={onSubmit}>
                      {({
                        handleSubmit,
                        values,
                        errors,
                        touched,
                        submitting,
                      }) => (
                        <div className="w-full">
                          <form onSubmit={handleSubmit}>
                            <fieldset style={{ width: "1200px" }}>
                              <Section>
                                <Fieldset>
                                  <Legend>
                                    Search By Phone or ApplicationId or FirstName
                                  </Legend>
                                  <div
                                    className="mb-4 md:mb-6"
                                    style={{ width: "60%" }}
                                  >
                                    <Field
                                      name="search.key"
                                      component={Input}
                                      label="Phone/App-Id/First-Name"
                                      validate={(v) => {
                                        if (!v) return "Mandatory";
                                        v = v.trim();
                                        if (!isValidLeadSearchInput(v))
                                          return "Invalid Input";
                                      }}
                                    />
                                  </div>
                                  <div className="save-button">
                                    <button onClick={handleSubmit}>Search</button>
                                  </div>
                                </Fieldset>
                              </Section>
                            </fieldset>
                          </form>
                        </div>
                      )}
                    </Form>
                  </FormSection>
                  <Fragment>
                    <Fragment>
                      {error ? (
                        <h2
                          className="text-2xl text-center mb-6"
                          style={{ color: "red" }}
                        >
                          {error}
                        </h2>
                      ) : (
                        !emptyList && (
                          <div>
                            <h2 className="text-2xl text-center mb-6">
                              Search Results For "{searchSuccess}"
                            </h2>
                            {searchSuccess === "UE_REJECTED" && (
                              <Filter
                                filter={rejectFilter}
                                handleFilter={handleFilter}
                              />
                            )}
                              <Fragment>
                                <div className="w-full mt-6 px-8">
                                  <DataGrid
                                    rows={leadList}
                                    columns={searchSuccess === "UE_REJECTED" ? ueColumns : columns}
                                    getRowId={(row) => row.leadAuthId}
                                    onRowClick={(params, event, details) => {
                                      router.push(`/lead/${params.row.leadAuthId}`);
                                    }}
                                    {...dataGridCommonProps}
                                  />
                                </div>
                              </Fragment>
                          </div>
                        )
                      )}
                    </Fragment>
                  </Fragment>
                </div>
              )}
            </Fragment>
          )}
          {activeTabIndex === 1 && (
            <Fragment>
              <BankAccount></BankAccount>
            </Fragment>
          )}
          {activeTabIndex === 2 && <RefiAccount />}
          {activeTabIndex === 3 && <SearchDisbursementLead />}
          {activeTabIndex === 4 && <ManualCSVUploads />}
        </>
      )}
    </Layout>
  );
};

export default Home;
