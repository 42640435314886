import axios from "axios";

export const requestDisbursementDetails = (disbursementSlug) =>
  axios.request({
    method: "get",
    url: `/api/disbursement/getDisbursementDetails?disbursementSlug=${disbursementSlug}`,
  });

export const requestDisbursementDocuments = (disbursementSlug) =>
  axios.request({
    method: "get",
    url: `/api/disbursement/getDisbursementDocuments?disbursementSlug=${disbursementSlug}`,
  });

export const requestLoanDetails = (disbursementSlug) =>
  axios.request({
    method: "get",
    url: `/api/route?path=disbursement_portal/getLoanDetails/${disbursementSlug}`,
  });

export const requestSeasonAndDocumentsType = (disbursementSlug) =>
  axios.request({
    method: "get",
    url: `/api/disbursement/getSeasonAndDocumentsType?disbursementSlug=${disbursementSlug}`,
  });

export const requestDocuments = (data, disbursementSlug) =>
  axios.request({
    method: "post",
    url: `/api/route?path=disbursement_portal/documents/request/${disbursementSlug}`,
    data,
  });

export const editDocuments = (data) =>
  axios.request({
    method: "put",
    url: `/api/route?path=disbursement_portal/documents`,
    data,
  });

export const searchLeadApi = "/api/route?path=portal/lead-search";

export const filterLeadByStageApi = (stage, pageNo) =>
  `/api/route?path=disbursement_portal/lead/${stage}?page=${pageNo}`;

export const nextStagesApi = (slug) =>
  `/api/route?path=disbursement_portal/nextStages/${slug}`;

export const requestStateChangeLog = (slug) =>
  axios.request({
    method: "get",
    url: `/api/route?path=disbursement_portal/getStateChangeLog/${slug}`,
  });

export const requestBankAccountDetails = (slug) =>
  axios.request({
    method: "get",
    url: `/api/route?path=disbursement_portal/getBankAccountDetails/${slug}`,
  });

export const requestFlywireDetails = (slug) =>
  axios.request({
    method: "get",
    url: `/api/route?path=disbursement_portal/fetchFlywireDetails/${slug}`,
  });

export const requestFlywireBankDetails = (key) =>
  axios.request({
    method: "get",
    url: `/api/route?path=disbursement_portal/flywire_bank_details/${key}`,
  });

export const requestFetchCollegeDetails = (slug) =>
  axios.request({
    method: "get",
    url: `/api/route?path=disbursement_portal/fetchCollegeDetails/${slug}`,
  });

export const requestFetchSableDetails = (slug) =>
  axios.request({
    method: "get",
    url: `/api/route?path=disbursement_portal/fetchBankAccountAmountDetails/${slug}`,
  });

export const requestDisbursementApplicationsList = (authId, stage) =>
  axios.request({
    method: "get",
    url: `/api/route?path=disbursement_portal/getAllDisbursementRequest/${authId}?stage=${stage}`,
  });

export const requestLeadDetails = (authId) =>
  axios.request({
    method: "get",
    url: `/api/route?path=lead/data?leadid=${authId}`,
  });

export const requestChangeState = (data) =>
  axios.request({
    method: "post",
    url: "/api/route?path=disbursement_portal/change_state",
    data,
  });

export const requestDisbursementDetailsUpdate = (data) =>
  axios.request({
    method: "post",
    url: "/api/route?path=disbursement_portal/updateDisbursementDetails",
    data,
  });

export const requestUpdateFlywireDetails = (data) =>
  axios.request({
    method: "post",
    url: "/api/route?path=disbursement_portal/updateFlywireDetails",
    data,
  });

export const requestUpdateDocumentStatus = (data) =>
  axios.request({
    method: "post",
    url: "/api/route?path=disbursement_portal/updateDocumentStatus",
    data,
  });

export const requestUpdateExpenseDetails = (data) =>
  axios.request({
    method: "post",
    url: "/api/route?path=disbursement_portal/updateExpenseDetails",
    data,
  });

export const requestCreateExpenseDetails = (data) =>
  axios.request({
    method: "post",
    url: "/api/route?path=disbursement_portal/upsell",
    data,
  });

  export const deleteDocumentRequest = (documentRequestId) =>
    axios.request({
      method: "delete",
      url: `/api/route?path=disbursement_portal/documents/request/${documentRequestId}`,
    });

export const requestUpdateStudentProfile = (data) =>
  axios.request({
    method: "put",
    url: "/api/route?path=disbursement_portal/student-profile",
    data,
  });

export const uploadAndSaveDisbursementFile = (formData) =>
  axios.request({
    method: "post",
    url: "/api/upload?path=disbursement_portal/uploadAndSaveDisbursementFile",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });