import Table from "rc-table";
import { Legend } from "components/Form/section";

const HeaderCell = (props) => (
  <th
    {...props}
    className="border border-gray-1010 p-2 px-4 font-bold text-left"
  />
);
const BodyCell = (props) => (
  <td
    {...props}
    className="border border-gray-1010 p-2 px-4 font-normal break-words max-w-[100px]"
  />
);

const tableStyle = (props) => <table {...props} className="w-full" />;

const components = {
  table: tableStyle,
  header: { cell: HeaderCell },
  body: { cell: BodyCell },
};

const SimpleTable = ({ columns = [], data = [], label = "", ...rest }) => (
  <>
    {label && (
      <Legend>
        <span className="block mb-4">{label}</span>
      </Legend>
    )}
    <Table
      className="bg-white border border-gray-300 text-[14px] w-full"
      components={components}
      columns={columns}
      data={data}
      {...rest}
    />
  </>
);

export default SimpleTable;
